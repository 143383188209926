@import '~react-md/src/scss/react-md';

.trade-row td {
  vertical-align: middle !important;
}
.trade-row .md-table-column {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.trade-row .with-text-button {
  padding-top: 7px !important;
  padding-bottom: 0 !important;
}
.trade-info input{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}