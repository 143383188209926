@import '~react-md/src/scss/react-md';
.search-toolbar {
  width: 200px !important;
  margin-left: auto;
}
.md-toolbar--text-white {
  .md-floating-label {
    color: $md-indigo-50;
  }
  .md-divider--text-field, .md-divider--text-field-active {
    background: $md-indigo-200 !important;
  }
  .md-divider--text-field-expanded::after {
    width: 0;
  }
}