@import '~react-md/src/scss/react-md';
.add-new-site, .edit-site, .add-trade, .add-new-trader {
  @media (min-width: $md-tablet-min-width) {
    min-width: 700px;
  }
}
.site-row {
  height: 60px;
  text-transform: uppercase;
}
.app .md-card-title--contextual {
  background: $md-blue-100;
  .md-card-title--title-contextual {
    color: $md-blue-700;
  }
}
.alexa-button {
  height: 60px !important;
  width: 150px;
  &.md-btn--hover {
    background: none;
  }
}
.alexa-info-big .md-tooltip{
  background: none;
}
.add-site-button {
  right: 100px !important;
}