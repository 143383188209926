body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
@import '~react-md/src/scss/react-md';
$md-primary-color: $md-indigo-500;
$md-secondary-color: $md-orange-a-200;
$md-typography-include-utilities: true !default;


@include react-md-everything;

.app-body {
  min-height: 300px;
}